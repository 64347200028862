// flatpick

.custom-flatpickr{
    font-size:  0.875rem;
    &:read-only {
        // Styles when the input has the readonly attribute
        background-color: transparent !important; // Light grey background
        cursor: pointer !important; // 'Not allowed' cursor for read-only state
    }
}

// .custom-flatpickr:disabled,
//  .custom-flatpickr[readonly] {
//     background-color: transparent !important; // Light grey background
//     cursor: pointer !important; // 'Not allowed' cursor for read-only state
// }