.testimonial-one img {
  display: unset !important;
  width: 90%;
}

.coinDetails-datepiker .picker__container {
  position: relative !important;
}

.wallet-card {
  margin: 0 auto;
  width: 95% !important;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button {
  display: inline-block;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label::after {
  background-color: rgba(1, 1, 1, 0) !important;
}

/* ////////////////////////fixed */
.element-right-round button#dropdown-button-drop-dwon {
  border-radius: 0 0.75rem 0.75rem 0;
}

._i-hover:hover {
  left: 0 !important;
}

@media screen and (min-width: 695px) {
  .d-unset {
    display: unset !important;
  }
}

.d-unset {
  display: block;
}

button#check-minutes {
  border-radius: 0 0.75rem 0.75rem 0;
}

.fc-toolbar-chunk {
  display: flex;
}

@media screen and (max-width: 335px) {
  .fc-toolbar-chunk {
    display: block;
    text-align: center;
    line-height: 2.8;
  }

  .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
    margin: 0 !important;
  }
}

.text-logo-img {
  width: 30% !important;
}

.logo-img {
  width: 15% !important;
}

/* Editor */
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

/* Map */
svg.svg-map {
  stroke: white !important;
}

.svg-map__location {
  fill: #eff2f4 !important;
}

.svg-map__location:focus,
.svg-map__location:hover {
  fill: black !important;
}

.map-unselected {
  fill: #eff2f4 !important;
}

.p-meddle {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

/* color picker */

.chrome-picker {
  position: absolute;
  top: -310px;
}

.color-gradian-tixia .popover {
  top: -410px !important;
}

/* wizard */

/* date piker */
@media screen and (max-width: 590px) {
  .rangeDatePicker .calendar__item {
    display: block !important;
    width: 100%;
  }

  .rangeDatePicker .calendar__container {
    width: 100% !important;
  }
}

form#step-form-horizontal div:first-child>div:last-child {
  text-align: right;
  /* padding-top: 2rem; */
}

label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eef5f9;
  text-align: center;
  margin: auto;
}

.wizard-four-color {
  color: #222fb9;
  text-decoration: underline;
}

.redio-false {
  display: none;
}

span.mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

span.mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

/* Wizard end */

/* cursor-pointer */
.cursor-pointer {
  cursor: pointer;
}

/* form */
#multiselect button.btn.dropdown-toggle.btn-light {
  background: no-repeat;
  border: 1px solid #d7dae3;
  color: #3e4954;
  font-weight: 100;
}

/* slider */

.card-text {
  margin-bottom: 0;
}

#whiteSpace {
  white-space: normal;
}

.tp-btn::after {
  content: "" !important;
  border: none !important;
}

.swal-footer {
  text-align: center !important;
  margin: 0 !important;
}

.ui .progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}

@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

ol.go2441762052 {
  text-align: center;
}

#order2 {
  order: 2;
}

/* Editor */

.summernote> :first-child {
  min-height: 250px;
  border: 1px solid #a9a9a9;
  border-color: #f0f1f5;
}

.rdw-image-modal {
  right: 0 !important;
  left: unset !important;
}

.rdw-editor-toolbar {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

/* Button */
button:focus,
a:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Chart */

.go2478912419 {
  text-align: center;
  display: block;
  margin-bottom: 2rem;
}

.picker-input__text,
.range-picker-input {
  width: 100% !important;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root {
  flex: 1 1 auto;
}

.timepicker-container input {
  background: #fff;
  border: 1px solid #f0f1f5;
  color: #b1b1b1;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  height: 41px;
  width: 100%;
  box-sizing: border-box;
}

.MuiFormControl-root.MuiTextField-root {
  display: block;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100%;
  height: 100%;
}

.dd .nestable-item-name {
  color: #fff;
  background: #e23428;
  display: block;
  height: 35px;
  margin: 5px 0;
  padding: 8px 10px;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid #e23428;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 13px;
  margin-bottom: -4px;
}

/* Picker */

.i-false:after {
  content: none !important;
}

rect#chart_ChartBorder {
  fill: transparent;
}

/* pagination */
.pagination {
  /* margin-bottom: 0 !important; */
}

@import url("https://fonts.googleapis.com/css?family=Lato");

.rc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 80;
}

.rc-backdrop.invert {
  background: rgba(1, 1, 1, 0.7);
}

.range-picker-input {
  display: inline-flex;
  border: 1px solid #e4e4e4;
  width: 300px;
}

.range-picker-input * {
  box-sizing: border-box;
}

.range-picker-input__icon {
  display: inline-flex;
  align-items: center;
}

.range-picker-input__start,
.range-picker-input__end {
  display: inline-flex;
  flex: 1;
}

.range-picker-input__start .picker-input.range input,
.range-picker-input__end .picker-input.range input {
  width: 100%;
  border: none;
}

.picker-input {
  display: inline-block;
  position: relative;
}

.picker-input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: inline-flex;
  align-items: center;
}

.picker-input__text {
  padding: 10px;
  border: 1px solid #e4e4e4;
  outline: none;
  font-size: 16.8px;
}

.picker-input__text:disabled {
  background: #e4e4e4;
}

.picker-input__clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.picker {
  display: inline-block;
}

.picker__container {
  position: absolute;
  z-index: 100;
}

.picker__container.portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.picker__container__include-time {
  border: 1px solid #e4e4e4;
}

.picker__container__include-time .calendar__item,
.picker__container__include-time .time__container {
  border: none;
}

.picker__container__tab {
  margin: 10px 0;
}

.picker__container__tab button {
  padding: 5px 10px;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 2px solid #e4e4e4;
}

.picker__container__tab button.active {
  color: #49599a;
  border-bottom: 2px solid #49599a;
}

.picker__container__tab button:first-child {
  border-right: none;
}

.picker__container__tab button svg {
  margin-right: 5px;
}

.time__container {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 15px;
  background: white;
  font-family: "Lato";
  font-size: 12px;
}

.time__container__div {
  margin: 0 10px;
}

.time__container__type {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.time-input {
  display: inline-block;
  width: 40px;
  overflow: hidden;
}

.time-input__up,
.time-input__down {
  border: 1px solid #e4e4e4;
}

.time-input__up button,
.time-input__down button {
  outline: none;
  width: 100%;
  cursor: pointer;
  border: none;
}

.time-input__text {
  width: 100%;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  box-sizing: border-box;
}

.time-input__text input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  outline: none;
}

/* calender */

.react-calendar {
  border: none !important;
  font-family: "poppins", sans-serif !important;
  width: 100% !important;
  font-size: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #dee2e6 !important;
  font-family: "poppins", sans-serif;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  font-family: "poppins", sans-serif;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  display: none;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  border-radius: 10px;
  color: #000;
  font-weight: 500;
  height: 60px;
  line-height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "poppins", sans-serif;
}

button.react-calendar__navigation__label {
  /* padding: 20px 15px; */
  border-radius: 1.75rem !important;
  font-size: 20px;
}

button.react-calendar__navigation__label:hover {
  color: white !important;
  background: #eeeeee !important;
}

button.react-calendar__navigation__label:hover>span {
  /* color: white !important; */
  /* background: #EEEEEE !important; */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation__label:hover>span {
  /* background-color: #eeeeee !important; */
  /* color: white; */
  /* border-radius: 50%; */
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive,
.react-calendar__navigation button[disabled],
.react-calendar__navigation button[disabled]>span {
  background-color: #eeeeee !important;

  /* color: white; */
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day,
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  position: relative;
  box-shadow: none;
  /* color: white !important; */
  border-radius: 10px;
  overflow: unset !important;
  padding: 16px 5px 15px 5px;
  /* border-radius: 35px; */
  line-height: 1;
  color: #fff;
  background: var(--primary) !important;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day::before,
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day::before {
  content: "";
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 1.4rem;
  right: -0.3125rem;
  top: -0.3125rem;
  position: absolute;
  border: 3px solid #fff;
  background: #FFC368;
}


.react-calendar {
  border: none;
}

.react-calendar__navigation button {
  font-size: 24px;
}

.calendar {
  display: inline-block;
  background: white;
  font-size: 12px;
}

.calendar *,
.calendar *:before,
.calendar *:after {
  box-sizing: border-box;
}

.calendar__container {
  width: 270px;
  font-family: "Roboto", sans-serif;
  display: none;
}

.calendar__list {
  display: table;
}

.calendar__item {
  display: table-cell;
  border: 1px solid #ececec;
}

.calendar__item:not(:first-child) {
  border-left: none !important;
}

.calendar--show {
  display: inline-block;
}

.calendar__head {
  position: relative;
  background: transparent;
  padding: 10px 6px;
}

.calendar__head--title {
  font-size: 1.3em;
  color: #9a9fa4;
  text-align: center;
  margin: 4px;
}

.calendar__head--button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 20px;
}

.calendar__head--button svg {
  fill: #9a9fa4;
}

.calendar__head--prev,
.calendar__head--next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.calendar__head--prev {
  left: 0;
}

.calendar__head--next {
  right: 0;
}

.calendar__panel--show {
  display: block !important;
}

.calendar__panel--today {
  background: #aab6fe;
  padding: 5px;
  display: none;
}

.calendar__panel--today h2 {
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}

.calendar__body--table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: none;
  border-collapse: collapse;
}

.calendar__body--table th {
  height: 30px;
  vertical-align: middle;
  color: #9a9fa4;
}

.calendar__day {
  vertical-align: top;
  padding-top: 5px;
  height: 40px;
  cursor: pointer;
}

/* .calendar__day self:hover:not(&--disabled) {
   background: #aab6fe;
   cursor: pointer;
} */
.calendar__day--0 {
  color: red;
}

.calendar__day--6 {
  color: blue;
}

.calendar__day--today {
  background: #fff9c4;
}

.calendar__day--disabled {
  color: #ddd;
  cursor: initial;
}

.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: #7986cb;
  color: #fff;
}

.calendar__day--start:hover,
.calendar__day--end:hover,
.calendar__day--selected:hover {
  background: #7986cb;
}

.calendar__day--range {
  background: #dce1ff;
}

.calendar__day--text {
  display: block;
  font-size: 10px;
}

.calendar__year,
.calendar__month {
  height: 55px;
  vertical-align: middle;
}

.calendar__year:hover,
.calendar__month:hover {
  background: #aab6fe;
  cursor: pointer;
}

/* Selector Design */
.css-1okebmr-indicatorSeparator {
  display: none;
}

.nestable .icon-minus-gray,
.nestable .icon-plus-gray {
  background-image: none;
}

.nestable .nestable-icon:before {
  content: "\f068";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  width: auto;
  height: auto;
  display: contents;
}

.nestable .nestable-item--children-collapsed .nestable-icon:before {
  content: "\f067";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  width: auto;
  height: auto;
  display: contents;
}


#tree {
  display: inline-block;
  padding: 10px;
}

#tree * {
  box-sizing: border-box;
}

#tree .branch {
  padding: 5px 0 5px 20px;
}

#tree .branch:not(:first-child) {
  margin-left: 170px;
}

#tree .branch:not(:first-child):after {
  content: "";
  width: 20px;
  border-top: 1px solid var(--primary);
  position: absolute;
  left: 150px;
  top: 50%;
  margin-top: 1px;
}

.entry {
  position: relative;
  min-height: 42px;
  display: block;
}

.entry:before {
  content: "";
  height: 100%;
  border-left: 1px solid var(--primary);
  position: absolute;
  left: -20px;
}

.entry:first-child:after {
  height: 10px;
  border-radius: 10px 0 0 0;
}

.entry:first-child:before {
  width: 10px;
  height: 50%;
  top: 50%;
  margin-top: 1px;
  border-radius: 10px 0 0 0;
}

.entry:after {
  content: "";
  width: 20px;
  transition: border 0.5s;
  border-top: 1px solid var(--primary);
  position: absolute;
  left: -20px;
  top: 50%;
  margin-top: 1px;
}

.entry:last-child:before {
  width: 10px;
  height: 50%;
  border-radius: 0 0 0 10px;
}

.entry:last-child:after {
  height: 10px;
  border-top: none;
  transition: border 0.5s;
  border-bottom: 1px solid var(--primary);
  border-radius: 0 0 0 10px;
  margin-top: -9px;
}

.entry:only-child:after {
  width: 10px;
  height: 0px;
  margin-top: 1px;
  border-radius: 0px;
}

.entry:only-child:before {
  display: none;
}

.entry span {
  border: 1px solid var(--primary);
  display: block;
  width: 150px;
  padding: 5px 10px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -15px;
  color: var(--primary);
  font-family: arial, verdana, tahoma;
  font-size: 14px;
  display: inline-block;
  border-radius: 5px;
  transition: all 0.5s;
}

#tree .entry span:hover,
#tree .entry span:hover+.branch .entry span {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

#tree .entry span:hover+.branch .entry::after,
#tree .entry span:hover+.branch .entry::before,
#tree .entry span:hover+.branch::before,
#tree .entry span:hover+.branch .branch::before {
  border-color: var(--primary);
}

.smbtn {
  font-size: 0.75rem !important;
  padding: 0rem 0.5rem !important;
  font-weight: 600 !important;
  border-radius: 25px !important;
}

.dragover {

  border: 2px solid red !important;
  border-style: dashed !important;
}

.folderhover {
  background-color: white;
  position: relative;
  padding-top: 5px;
  border-radius: 5px;
}

.folderhover:hover {
  border: 1px solid var(--primary);
}

.folderhover .select-icon {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 14px;
  border-radius: 25px;
  background-color: white;
}

.folderhover .select-icon i {
  color: var(--bs-success);
  font-size: 15px;
}

.img-folder {
  height: 130px;
  width: calc(100% - 30px);
  object-fit: contain;
}

.folderhover .video {
  height: 130px;
  width: calc(100% - 10px);
  object-fit: cover;
  border-radius: 5px;
}

.folderhover .webpage {
  height: 130px;
  width: calc(100% - 10px);
  object-fit: cover;
  border-radius: 5px;
}

.img-image {
  height: 130px;
  width: calc(100% - 10px);
  object-fit: cover;
  border-radius: 5px;
}

.tag-playlist {
  background-color: #f0f0f091;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0px;
}

.playlist-item {
  padding-bottom: 10px;
  overflow-x: auto;
  display: flex;
}

.playlist-item::-webkit-scrollbar {
  width: 2px;
}

.playlist-item::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
}

.playlist-item::-webkit-scrollbar-track {
  background-color: #888;
  border-radius: 10px;
}

/* preview screen */

.device-container {
  height: 500px;
  background-color: #201f1f;
  box-shadow: 3px 3px 7px 1px #464242;
}

.device-container.device-container-sm {
  height: 250px !important;
}

.device-container.device-container-sm.device-container-vertical {
  height: 450px !important;
  width: 300px !important;
}

.device-container.device-container-vertical {
  height: 600px !important;
}

.device-container.device-container-sm.device-container-vertical .slider {
  height: calc(450px - 30px) !important;
}

.device-container.device-container-vertical .slider {
  height: calc(600px - 30px) !important;
}

.device-container.device-container-sm .slider {
  height: calc(250px - 30px) !important;
}

.slider {
  display: flex;
  height: calc(500px - 30px);
  /* margin: 10px 10px 10px 10px; */
  background: #000;
  border-radius: 5px;

}

.slide {
  min-width: 100%;
  box-sizing: border-box;
  display: none;
  position: relative;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.loading {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30%;
}

.device-bottom {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news-ticker {
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
}

.news {
  display: inline-block;
  padding-right: 20px;
  padding-left: 20px;
  animation: marquee 30s linear infinite;
  color: #e5dcdc;
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.news-item {
  margin-right: 40px;
  margin-left: 3px;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.single-line-wrap {
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Hides overflow text */
  text-overflow: ellipsis;
  /* Adds the ellipsis (...) */
  width: calc(100% - 10px);
  /* Specify a width for the container */
}

.profile-text {
  font-size: 3rem;
  color: #2230cf;
  background-color: #2230cf30;
  border-radius: 50%;
  padding: 16px 33px;
}

.file-upload-box {
  width: 150px;
  height: 150px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f9f9f9;
}

.file-upload-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-upload-box p {
  color: #888;
  text-align: center;
}

.otp-input-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.otp-input-box {
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.otp-input-box:focus {
  border-color: var(--bs-primary);
}


.text-avatar {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  border-radius: 51%;
  text-align: center;
  line-height: 30px;
  width: 30px;
  height: 30px;
  box-shadow: 1px 2px 3px 0px #6b6a6a;
}


.text-avatar-square {
  font-size: 2.5rem;
  border-radius: 10px;
  font-weight: 800;
  color: white;
  text-align: center;
  line-height: 100px;
  width: 100px;
  height: 100px;
  box-shadow: 1px 2px 3px 0px #6b6a6a;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  /* Dark background with transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's on top */
}

.fullscreen-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* Keep aspect ratio */
}

.fullscreen-overlay .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}

.fullscreen-overlay .zoom-controls {
  position: absolute;
  top: 20px;
  left: 50%;
  color: #e8e6e6;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
  display: flex;
}

.fullscreen-overlay .zoom-controls .zoom-in{
  margin-right: 1rem;
}


.profile-pic-sm img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

@media (max-width:1050px) {
  .profile-pic-sm img {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .profile-pic-sm img {
    width: 10px;
    height: 10px;
  }
}

.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
}

.bg-light-gray {
  background-color: #fbfbfb !important;
}
.text-underline{
  text-decoration: underline;
}

/* .custom-flatpickr {
  appearance: none;
  background: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' fill='%23999' viewBox='0 0 24 24'><path d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H5V9h14v12zm0-14H5V5h14v2z'/></svg>")
    no-repeat right 0.6rem center;
  background-size: 15px 15px;
  padding-right: 3rem;
  max-width: 100vw;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
} */


/* loader */

/* 
.loading-section {
  flex: 1 1 25%;
} */

.loading-text {
  color: var(--bs-primary);
  animation: fadeInOut 1.5s infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.sk-wave {
  width: 6em;
  height: 4em;
  margin: auto;
  text-align: center;
  font-size: 1em;
}
.sk-wave .sk-rect {
  background-color: var(--bs-primary);
  height: 100%;
  width: 0.5em;
  margin-right: .2rem;
  display: inline-block;
  -webkit-animation: sk-wave-stretch-delay 1.2s infinite ease-in-out;
          animation: sk-wave-stretch-delay 1.2s infinite ease-in-out;
}
.sk-wave .sk-rect-1 {
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.sk-wave .sk-rect-2 {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-wave .sk-rect-3 {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-wave .sk-rect-4 {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-wave .sk-rect-5 {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

@-webkit-keyframes sk-wave-stretch-delay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

@keyframes sk-wave-stretch-delay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
