.header-left {
  height: 100%;
  display: flex;
  align-items: center;
  .breadcrumb {
    margin-bottom: 0;
  }
  .agenda {
    a {
      border-radius: 1.75rem;
      i {
        margin-right: 0.75rem;
        @include respond("laptop") {
          margin-right: 0rem;
        }
      }
      @include custommq($max: 93.75rem) {
        font-size: 0;
        border-radius: 0.75rem;
        padding: 0.8125rem 0.9375rem;
        i {
          font-size: 1.25rem;
          margin-right: 0;
        }
      }
    }
    @include respond("phone") {
      display: none;
    }
  }
  .dashboard_bar {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--secondary);
    @include respond("tab-land") {
      font-size: 2.125rem;
    }
    @include respond("phone-land") {
      display: none;
    }
    &.sub-bar {
      font-size: 1.875rem;
    }
  }
  .search-bar {
    position: relative;

    // Add border when input inside .search-bar is focused
    &:focus-within {
      //border: 1px solid $primary;
      .ctrl-btn {
        display: none;
      }
    }

    &.show-result {
      .search-area-result {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        @include respond("phone") {
          visibility: hidden !important;
          opacity: 0 !important;
        }
      }
      .search-area {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }

    .search-area {
      width: 18rem;
      border: 1px solid $border-color !important;
      border-radius: .8rem;
      background-color: #F0F4F9 !important;
      padding: 0 1rem;
      .search-icon {
        font-size: 1.2rem;
      }

      @include custommq($max: 100rem) {
        width: 18.75rem;
      }

      @include respond("phone") {
        visibility: hidden;
        opacity: 0;
      }

      .form-control {
        border: 0;
        color: $black;
        background: transparent;
        font-size: 0.875rem;
        font-weight: 400;
        padding-left: .5rem;
        transition: border-color 0.3s ease;
        &:focus {
          outline: none;
        }

        @include respond("laptop") {
          height: 3rem;
        }
      }

      .input-group-text {
        background-color: transparent;
        border: 0;
        min-width: auto !important;
        padding: 0rem !important;
        @include respond("laptop") {
          height: 3rem;
        }
      }

      .ctrl-btn {
        .ctrl-btn-code {
          border: 0.5px solid $border-color;
          font-size: 0.6rem;
          padding: 0.2rem 0.4rem;
          border-radius: 0.3rem;
          background-color: #f1f1f1;
          color: $gray;
          font-weight: 700;
        }
        .ctrl-btn-plus {
          padding: 0px 0.1rem;
        }
      }
    }

    .search-area-result {
      opacity: 0;
      visibility: hidden;
      width: 18rem;
      position: fixed;
      padding: 0px;
      height: auto;
      max-height: 300px;
      background: #F0F4F9;
      border: 1px solid $border-color;
      border-top: 0;
      border-bottom-left-radius: .8rem;
      border-bottom-right-radius: .8rem;
      overflow-y: auto;
      overflow-x: hidden;

      @include custommq($max: 100rem) {
        width: 18.75rem;
      }

      @include respond("phone") {
        visibility: hidden;
        opacity: 0;
      }

      .search-area-result-item {
        margin-bottom: .2rem;
        padding: .5rem 1rem;
        &.selected{
          background-color: $primary;
          color: $white;
          transition: background-color 0.3s ease;
        }
        &:hover{
          background-color: $primary;
          color: $white;
          transition: background-color 0.3s ease;
        }
        span {
          font-size: 1rem;
          cursor: pointer;

          &:hover {
            font-weight: 700;
          }
        }
      }

      /* For WebKit Browsers (Chrome, Safari) */
      &::-webkit-scrollbar {
        width: 5px; /* Set the width of the scrollbar */
      }
      &::-webkit-scrollbar-thumb {
        background-color: $primary; /* Set the color of the thumb */
        border-radius: 6px; /* Set the border radius of the thumb */
      }
      &::-webkit-scrollbar-track {
        background-color: transparent; /* Set the color of the track */
      }
      /* Optional: Style the scrollbar buttons (arrows) */
      &::-webkit-scrollbar-button {
        background-color: transparent; /* Set the color of the buttons */
      }
      /* For Firefox */
      scrollbar-color: #888 #f1f1f1;
    }
  }
}
// [data-headerbg="color_4"] {
//   // .search-area {
//   //   background-color: transparent !important;
//   // }
// }

[data-sidebar-style="compact"] {
  .header-left {
    margin-left: 0;
  }
}
