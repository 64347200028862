.c-pointer {
  cursor: pointer;
}
.react-select {
  .select__control {
    &.select__control--is-focused {
      border-color: $primary;
      box-shadow: none;
      border-width: 1px;
    }
  }
  &.is-invalid {
    .select__control {
      border-color: $danger !important;
    }
  }
}


.my-loader {
  border: 2px solid rgb(245, 242, 242); /* Light grey */
  border-left: 2px solid #273773; /* Blue */
  border-radius: 60%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}